<template>
  <v-container class="pa-0">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <div
          class="secondary rounded-lg mt-8"
          style="height: 24px; width: 8px"
        />

        <v-card
          class="py-3 py-md-6 px-4 px-md-8 mb-4"
          color="surface"
          width="100%"
          flat
          tile
        >
          <div
            class="d-flex flex-column flex-md-row align-md-center justify-space-between mb-6"
          >
            <div class="d-flex align-center mb-2 mb-md-0">
              <back-button class="mr-2" />

              <div class="text-h5 font-weight-bold flex-grow-1">
                <span class="saira"> Meus jogos </span>
              </div>
            </div>

            <v-btn
              v-if="step === 1"
              class="rounded-l-0 rounded-r-lg"
              color="primary"
              outlined
              @click="step = 2"
            >
              <v-icon left>mdi-plus</v-icon> Adicionar jogo
            </v-btn>
          </div>

          <div v-if="step !== 1" class="mb-4">
            <v-btn class="px-1 text-none" plain @click="step -= 1">
              <v-avatar size="26" color="primary" class="mr-2" rounded>
                <v-icon small>mdi-arrow-left</v-icon>
              </v-avatar>

              <span> Voltar </span>
            </v-btn>
          </div>

          <loader-content v-if="loading" />

          <v-window v-else v-model="step">
            <v-window-item :value="1">
              <!-- my games -->
              <div v-if="step === 1">
                <v-row v-if="myGames.length > 0">
                  <v-col
                    v-for="(item, i) in myGames"
                    class="d-flex flex-column align-center"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                    :key="i"
                  >
                    <v-card color="accent" class="mb-4" width="100%">
                      <v-img :src="item.game.thumb" :aspect-ratio="3 / 4">
                        <div class="d-flex align-center justify-center py-2">
                          <v-chip color="#000000CC" label>
                            {{ item.game.name }}
                          </v-chip>
                        </div>
                      </v-img>
                    </v-card>

                    <v-btn class="text-none" color="secondary" outlined small>
                      <div class="d-flex">
                        <span class="overtext--text text-body-2 mr-1">
                          {{ item.nickName }}
                        </span>

                        <span class="subtext--text text-body-2">
                          {{ item.cod }}
                        </span>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-else class="text-body-1 subtext--text mb-8">
                  Nenhum jogo foi adicionado.
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="2">
              <!-- game options list -->
              <div v-if="step === 2">
                <v-row v-if="_modalities.length > 0">
                  <v-col
                    v-for="(item, i) in _modalities"
                    class="d-flex flex-column align-center"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                    :key="i"
                  >
                    <v-card color="accent" class="mb-4" width="100%">
                      <v-img :src="item.thumb" :aspect-ratio="3 / 4">
                        <div class="d-flex align-center justify-center py-2">
                          <v-chip color="#000000CC" label>
                            {{ item.name }}
                          </v-chip>
                        </div>
                      </v-img>
                    </v-card>

                    <v-btn
                      class="rounded-l-0 rounded-r-lg"
                      color="primary"
                      :disabled="!item.active"
                      outlined
                      small
                      block
                      @click="
                        selected = item;
                        step = 3;
                      "
                    >
                      <v-icon left>mdi-plus</v-icon> Adicionar
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-else class="text-body-1 subtext--text mb-8">
                  Nenhum jogo disponível.
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="3">
              <div v-if="step === 3">
                <!-- add game -->
                <v-row>
                  <v-col cols="12" md="4">
                    <v-img :src="selected.thumb" :aspect-ratio="3 / 4" />
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-form
                      v-model="valid"
                      ref="form"
                      autocomplete="off"
                      class="mb-6"
                    >
                      <div @keypress.enter.prevent="submit()" class="mb-4">
                        <div class="label-input">Nome de usuário do jogo</div>
                        <v-text-field
                          v-model="form.nickname"
                          class="rounded-lg"
                          background-color="accent"
                          placeholder="Seu nome"
                          :rules="rules"
                          solo
                          flat
                          @keydown.space.prevent
                        />

                        <div class="label-input">Tag ou ID</div>
                        <v-text-field
                          v-model="form.cod"
                          class="rounded-lg"
                          background-color="accent"
                          placeholder="Seu nome"
                          :rules="rules"
                          solo
                          flat
                          @keydown.space.prevent
                        />
                      </div>

                      <div class="d-flex">
                        <v-spacer />

                        <v-btn
                          class="rounded-l-0 rounded-r-lg text-none"
                          color="primary"
                          width="128"
                          large
                          @click="submit()"
                        >
                          Confirmar
                        </v-btn>
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
              </div>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getUserGames, saveUserGame } from "@/services/user";

export default {
  name: "GamesComponent",
  data() {
    return {
      loading: true,
      myGames: [],
      step: 1,
      selected: null,
      valid: false,
      form: {
        nickname: "",
        cod: "",
      },
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["localUser", "modalities"]),

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _modalities() {
      const myGames = this.myGames.map((e) => e.gameId);

      return this.modalities.filter((e) => !myGames.includes(e.id));
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          ...this.form,
          gameId: this.selected.id,
        };

        await saveUserGame(payload).then(() => {
          this.getData();
          this.resetForm();
          this.step = 1;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    async getData() {
      try {
        await getUserGames().then((res) => {
          this.myGames = res.games;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.form.nickname = "";
      this.form.cod = "";
      this.selected = null;
    },
  },
};
</script>

<style></style>
